import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const freeOrder = (courseId) => {
  return ajax.post(`${base}/api/itedu/v1/order/free?courseId=${courseId}`);
};

const create = (courseId, referPhone, reducePrice) => {
  return ajax.post(
    `${base}/api/itedu/v1/order/create?courseId=${courseId}&referPhone=${referPhone}&reducePrice=${reducePrice}`
  );
};

const discountByRefer = (courseId, referPhone) => {
  return ajax.get(`${base}/api/itedu/v1/order/discount-by-refer?courseId=${courseId}&referPhone=${referPhone}`);
};

const get = (orderId) => {
  return ajax.get(`${base}/api/itedu/v1/order?orderId=${orderId}`);
};

const queryOrderPayment = (orderId) => {
  return ajax.get(`${base}/api/itedu/v1/order/payment?orderId=${orderId}`);
};

export const orderApi = {
  // import时得花括号明确
  freeOrder: freeOrder,
  create: create,
  discountByRefer: discountByRefer,
  get: get,
  queryOrderPayment: queryOrderPayment,
};
