import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const term = (termId, start, len) => {
  return ajax.get(`${base}/api/itedu/v1/course/term?termId=${termId}&start=${start}&len=${len}`);
};

const courseSet = (courseId) => {
  return ajax.get(`${base}/api/itedu/v1/course/course-set?courseId=${courseId}`);
};

const courseInfo = (courseId) => {
  return ajax.get(`${base}/api/itedu/v1/course/info?courseId=${courseId}`);
};

const subjectCourseSetList = () => {
  return ajax.get(`${base}/api/itedu/v1/tiku/course/getTikuCourseSet`);
};

export const courseApi = {
  // import时得花括号明确
  term: term,
  courseSet: courseSet,
  courseInfo: courseInfo,
  subjectCourseSetList: subjectCourseSetList,
};
