import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const startPay = (openid, payType, orderId, amount) => {
  return ajax.post(`${base}/api/payment/pay?openid=${openid}&payType=${payType}&orderId=${orderId}&amount=${amount}`);
};

const startPayWithoutOpenId = (payType, orderId, amount) => {
  return ajax.post(`${base}/api/payment/pay?payType=${payType}&orderId=${orderId}&amount=${amount}`);
};

const query = (orderId) => {
  return ajax.get(`${base}/api/payment/query?orderId=${orderId}`);
};

export const payApi = {
  // import时得花括号明确
  startPay: startPay,
  startPayWithoutOpenId: startPayWithoutOpenId,
  query: query,
  AppID: "wx0fa542fc0e97c45d",
};
