<template>
  <div class="pay-list">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        订单确认
      </div>
      <div class="right"></div>
    </div>
    <div class="main">
      <div class="title">
        {{ courseVO ? courseVO.course.courseName : "" }}
      </div>
      <div class="price">
        ¥
        <span class="num">{{ courseVO ? courseVO.course.truePrice : "" }}</span>
      </div>
    </div>
    <div class="pay-opt">
      <div class="item">
        <div class="left">
          <img :src="wechatImg" />
          <div class="title">微信</div>
        </div>

        <div class="opt">
          <input type="radio" id="one" value="wx" v-model="payType" />
        </div>
      </div>
      <div class="item">
        <input class="refer-phone" type="text" placeholder="推荐人手机号" v-model="referPhone" />
      </div>
      <div class="qrcode" v-if="isPcPay">
        <div ref="qrCodeDiv"></div>
        <div class="text">
          扫描上方二维码进行付款
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <span class="label">合计:</span>
        <span class="price"> <span class="yuan">￥</span>{{ truePrice }}</span>
        <span class="discount" v-if="orderDiscountVO && orderDiscountVO.reducePriceLong"
          >已优惠 ￥ {{ orderDiscountVO.reducePrice }}</span
        >
      </div>
      <div class="right">
        <button class="btn disable" v-if="payReady && isPaying">
          支付中
        </button>
        <button
          class="btn"
          :class="{ disable: courseVO.ownThisCourse == 1 }"
          @click="onPay"
          v-if="payReady && isPaying == false"
        >
          {{ courseVO.ownThisCourse == 1 ? "购买成功" : "确认支付" }}
        </button>
      </div>
    </div>
    <div class="cs" @click="onClickShowCsWidget">
      <img :src="csBtn" />
    </div>
    <loginWidgetV2 v-if="showLoginWidget"></loginWidgetV2>
    <csWidget v-if="showCsWidget" :csId="'1'"></csWidget>
  </div>
</template>

<script>
import csWidget from "@/components/csWidget.vue";
import QRCode from "qrcodejs2";
import { Notify } from "vant";
import loginWidgetV2 from "@/components/loginWidgetV2.vue";

import { userCenterApi } from "@/api/userCenterApi.js";
import { orderApi } from "@/api/orderApi.js";
export default {
  props: {
    courseVO: Object,
    qrUrl: String,
    payReady: Boolean,
  },
  components: {
    loginWidgetV2,
    csWidget,
  },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      wechatImg: require("@/assets/images/wechat-icon.png"),
      customserviceImg: require("@/assets/images/custom-service.png"),
      csBtn: require("@/assets/images/cs-btn.png"),
      payType: "wx",
      showLoginWidget: false,
      showCsWidget: false,
      isPcPay: false,
      referPhone: "",
      orderDiscountVO: {
        courseId: 0,
        reducePrice: 0,
        totalFee: 0,
        reducePriceLong: 0,
        totalFeeLong: 0,
        bonusPriceLong: 0,
        referPhone: 0,
      },
      courseId: 0,
      truePrice: this.courseVO.course.truePrice,
      isPaying: false,
    };
  },
  methods: {
    getId() {
      if (this.$route.query && this.$route.query.id) {
        this.courseId = this.$route.query.id;
      }
      return this.courseId;
    },
    onGoBack() {
      let url = "";
      if (process.env.NODE_ENV == "development") {
        url = "http://ckw.kaiweitalk.com/courseDetail?id=" + this.courseId;
      } else {
        url = "http://aida101.com/courseDetail?id=" + this.courseId;
      }
      if (url) {
        window.location.href = url;
      } else {
        this.$router.go(-1);
      }
    },
    onPay() {
      if (this.courseVO.ownThisCourse == 1) {
        Notify({
          type: "success",
          message: "已购买课程，到我的课程查看",
        });
        return;
      }
      if (this.needLogin()) {
        return;
      }
      // 如果登录了才跳转、否则弹出登录
      userCenterApi
        .uid()
        .then((ret) => {
          console.log(ret);
          if (ret.code == 0 && ret.data) {
            this.isPaying = true;
            this.$emit("on-pay", this.orderDiscountVO);
          } else {
            localStorage.clear();
            this.showLoginWidget = true;
          }
        })
        .catch((err) => {
          userCenterApi.logoutV2().then((ret) => {
            localStorage.clear();
            this.showLoginWidget = true;
          });
        });
    },
    needLogin() {
      if (!this.courseVO.isLogin) {
        localStorage.clear();
        this.showLoginWidget = true;
      }
      return this.showLoginWidget;
    },
    listenOnCloseLoginWidget() {
      this.$EventBus.$on("onCloseLoginWidget", (val) => {
        this.showLoginWidget = false;
      });
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
    onClickShowCsWidget() {
      this.showCsWidget = true;
    },
  },
  mounted() {
    const courseId = this.getId();
    this.listenOnCloseLoginWidget();
    this.listenOnCloseCsWidget();
  },
  watch: {
    qrUrl: function(val) {
      console.log("qrUrl", this.qrUrl);
      if (!val) {
        return;
      }
      this.isPcPay = true;
      this.$nextTick(() => {
        const qrcode = new QRCode(this.$refs.qrCodeDiv, {
          /* eslint-disable */
          width: 240,
          height: 240,
          text: val, // 二维码地址
          colorDark: "#000",
          colorLight: "#fff",
        });
      });
    },
    courseVO: function(val) {
      if (val && val.course) {
        this.truePrice = val.course.truePrice;
      }
    },
    referPhone: function(val) {
      if (val && val.length == 11) {
        console.log("referPhone", val, val.length);
        orderApi.discountByRefer(this.courseId, val).then((ret) => {
          console.log("discount by refer,", ret);
          if (ret.code == 0 && ret.data) {
            this.orderDiscountVO = ret.data;
            this.truePrice = this.orderDiscountVO.totalFee;
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pay-list {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right {
      width: 27px;
    }
  }
  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0 0 auto;
    margin: 20px 17px 12px 17px;
    background: #fff;
    // width: 100%;
    height: 60px;
    border-radius: 12px;
    .title {
      font-size: 16px;
      color: #323233;
      letter-spacing: 0.36px;
      margin: 19px 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .price {
      font-size: 16px;
      color: #323233;
      letter-spacing: 0.36px;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-right: 20px;
    }
    //flex: 1 1 auto;
  }
  .pay-opt {
    flex: 1 1 auto;
    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 0 0 auto;
      margin: 0px 17px 12px 17px;
      background: #fff;
      // width: 380px;
      height: 66px;
      border-radius: 12px;
      .left {
        margin-left: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        color: #323233;
        letter-spacing: 0.36px;
        img {
          width: 34px;
          height: 34px;
        }
        .title {
          margin: 0 0 0 12px;
        }
      }
      .opt {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 15px;
        input[type="radio"] {
          margin-right: 5px;
          cursor: pointer;
          font-size: 18px;
          width: 15px;
          height: 20px;
          position: relative;
        }
        input[type="radio"]:after {
          position: absolute;
          width: 15px;
          height: 20px;
          top: 0;
          left: -2px;
          content: " ";
          background-color: #ff8423;
          color: #fff;
          display: inline-block;
          visibility: visible;
          padding: 0px 3px;
          border-radius: 50%;
        }
        input[type="radio"]:checked:before {
          content: "✓";
          display: block;
          position: relative;
          top: -2px;
          left: 1px;
          width: 6px;
          height: 6px;
          color: #fff;
          font-weight: 400;
          z-index: 1;
        }
      }
      .refer-phone {
        // height: 100%;
        width: 100%;
        border: none;
        background: inherit;
        border-radius: inherit;
        padding: 11px 0 11px 13px;
        letter-spacing: 2.8px;
        font-size: 20px;
        &::placeholder {
          color: #919191;
        }
      }
    }
    .qrcode {
      margin: 0px 17px 17px 17px;
      padding: 17px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 12px 12px 12px 12px;
      img {
        margin: 20px 0 32px 0;
        width: 300px;
        height: auto;
      }
      .text {
        margin: 32px 0 30px 0;
        font-weight: 500;
        font-size: 16px;
        color: #323233;
        letter-spacing: 0.36px;
      }
    }
  }
  .footer {
    flex: 0 0 auto;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    border-top: 1px solid #d1d1d1;
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .label {
        color: rgb(46, 46, 47);
        font-size: 16px;
        font-weight: 500;
        margin: 0 12px 0 18px;
      }
      .price {
        .yuan {
          font-size: 18px;
        }
        font-size: 26px;
        color: #ff8423;
        letter-spacing: 0.58px;
        line-height: 25px;
        font-weight: 600;
      }
      .discount {
        margin: 0 0 0 15px;
        font-size: 14px;
        font-weight: 500;
        color: #ff4040;
      }
    }
    .right {
      .btn {
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0.4px;
        font-weight: 500;
        background: #ff8423;
        box-shadow: 0 2px 4px 0 #ff8423;
        border-radius: 16px;
        border: none;
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0.4px;
        font-weight: 500;
        width: 120px;
        height: 42px;
        margin: 13px 17px 17px 0;
      }
      .disable {
        background: #c7c7c7;
        box-shadow: 0 2px 4px 0 #c7c7c7;
      }
    }
  }
}

.cs {
  height: 50px;
  width: 50px;
  position: absolute;
  left: 23px;
  bottom: 130px;
  z-index: 1;
  img {
    width: 50px;
    height: auto;
  }
}
</style>
